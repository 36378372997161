import Vue from 'vue'
import Vuex from 'vuex'
import projects from '../json/projects.json'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    practices:[],
    services:[],
    service:{},
    practice: {
      type: "",
      img: "",
      name: "",
      title: "",
      id: null
    }

  },
  mutations: {
    SET_PRACTICES(state, list){
      state.practices = list
    },
    SET_SERVICES(state, list){
      state.services = list
    },
    SET_PRACTICE(state, data) {
      state.practice = data
    },
    SET_SERVICE(state, data){
      state.service = data
    },
  },
  actions: {
    updatePractice ({ commit },  data ) {
      commit("SET_PRACTICE",  data )
    },
    loadService ({ commit }, service){
      debugger
      var data = projects.find((element) => element.name.replace(/\s+/g, "").toLowerCase() === service.toLowerCase())
      debugger
      commit("SET_SERVICE", data)
    },
    loadServices ({ commit }, practice){
      var list = projects.filter((element) => element.type === practice)
      commit("SET_SERVICES", list)
    },
    loadPractice ({ commit }, practice){
      debugger
      var data = projects.find((element) => {
        var temp = element.name
        if(temp.replace(/\s+/g, "").toLowerCase() === practice.toLowerCase() && element.type === "all"){
          return element
        }
      })
      commit("SET_PRACTICE", data)
    }
  },
  modules: {
  }
})
