<template>
  <div class="header-top-area">
    <b-container>
      <b-row>
        <b-col md="6">
          <div class="header-top-left">
            <p>
              <i class="fa fa-envelope"></i>
              infor@theassociates.me
            </p>
            <p>
              <i class="fa fa-phone"></i>
              Call Us: +961 3 373993
            </p>
          </div>
        </b-col>
        <b-col md="3">
          <div class="header-top-right">
            <social-menu/>
          </div>
        </b-col>
       <b-col md="3">
        <div class="header-quote-btn">
          <a  v-b-toggle.sidebar-backdrop >get a quote</a >
        </div>
      </b-col>
      </b-row>
    </b-container>
<!--    <b-sidebar-->
<!--        id="sidebar-backdrop"-->
<!--        backdrop-variant="dark"-->
<!--        backdrop-->
<!--        shadow-->
<!--    >-->
<!--      <div class="px-3 py-2">-->
<!--        <contact-form-area/>-->

<!--&lt;!&ndash;        <b-form-group label="Backdrop variant" label-for="backdrop-variant">&ndash;&gt;-->
<!--&lt;!&ndash;          <b-form-select id="backdrop-variant" v-model="variant" :options="variants"></b-form-select>&ndash;&gt;-->
<!--&lt;!&ndash;          <contact-form-area/>&ndash;&gt;-->
<!--&lt;!&ndash;        </b-form-group>&ndash;&gt;-->
<!--      </div>-->
<!--    </b-sidebar>-->
  </div>
</template>

<script>
import SocialMenu from "./SocialMenu";
import ContactFormArea from "@/components/ContactFormArea";
export default {
  name: "NavMenu",
  components: {SocialMenu, ContactFormArea}
}
</script>

<style scoped>

</style>