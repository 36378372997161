<template>
  <!-- Company Values Area Start -->
  <section class="ekifa-company-value-area">
    <b-container>
      <b-row>
        <b-col md="5">
          <div class="value-left">
            <img :src="require('../assets/img/value_man.png')" alt="value" />
          </div>
        </b-col>
        <b-col md="7">
          <div class="value-right">
            <h3>A lot more than a law firm.</h3>
            <p>Ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip.</p>
<!--            <div>-->
<!--              &lt;!&ndash; Company Values Chart Component &ndash;&gt;-->
<!--              <company-value-chart/>-->
<!--            </div>-->
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
  <!-- Company Values Area End -->
</template>

<script>
import CompanyValueChart from "./charts/CompanyValueChart";
export default {
  name: "CompanyArea",
  components: {CompanyValueChart}
}
</script>

<style scoped>

</style>