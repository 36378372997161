<template>
    <!-- Header Area Start -->
  <header class="header-fixed">
<!--  <header class="ekifa-header-area">-->
      <!-- Header Nav Menu -->
      <nav-menu/>
      <div class="header-mainmenu" style="background-color: white;">
        <!-- Header Main Menu -->
        <main-menu/>
      </div>
    </header>
    <!-- Header Area End -->
</template>

<script>
import NavMenu from "../components/menus/NavMenu";
import MainMenu from "../components/menus/MainMenu";
export default {
  name: "AppHeader",
  components: {MainMenu, NavMenu}
}
</script>

<style scoped>
.header-fixed {
  position: sticky;
  top: 0;
  z-index: 1020;
}
</style>