<template>
  <!-- Testimonial Area Start -->
  <section class="ekifa-testimonial-area section_80">
    <b-container>
      <b-row>
        <b-col>
          <div class="site-heading">
            <h2>client's reviews</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis accumsan nisi Ut ut felis congue nisl hendrerit commodo. </p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="testimonial-slider owl-carousel">
            <carousel
                :autoplay="true"
                :autoplayTimeout="10000"
                :loop="true"
                :navigationEnabled="false"
                :per-page="1"
                :speed="700"
                :center-mode="true"
                paginationActiveColor="#ee2727"
                paginationColor="#0a172c"
            >
              <slide>
                <div class="single-testimonial">
                  <div class="testimonial-img">
                    <img :src="require('../assets/img/testimonial-1.jpg')" alt="testimonial" />
                  </div>
                  <div class="testimonial-text">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus molestie, justo nec convallis sollicitudin, sapien lorem dictum lacus, non consequat odio ipsum nec est.</p>
                  </div>
                  <div class="testimonial-info">
                    <h4>John Doe</h4>
                    <p>CEO,ABC Company</p>
                  </div>
                </div>
              </slide>
              <slide>
                <div class="single-testimonial">
                  <div class="testimonial-img">
                    <img :src="require('../assets/img/testimonial-2.jpg')" alt="testimonial" />
                  </div>
                  <div class="testimonial-text">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus molestie, justo nec convallis sollicitudin, sapien lorem dictum lacus, non consequat odio ipsum nec est.</p>
                  </div>
                  <div class="testimonial-info">
                    <h4>John Smith</h4>
                    <p>Chairman,ABC Company</p>
                  </div>
                </div>
              </slide>
              <slide>
                <div class="single-testimonial">
                  <div class="testimonial-img">
                    <img :src="require('../assets/img/testimonial-3.jpg')" alt="testimonial" />
                  </div>
                  <div class="testimonial-text">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus molestie, justo nec convallis sollicitudin, sapien lorem dictum lacus, non consequat odio ipsum nec est.</p>
                  </div>
                  <div class="testimonial-info">
                    <h4>Joe Smith</h4>
                    <p>CEO,ABC Company</p>
                  </div>
                </div>
              </slide>
            </carousel>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
  <!-- Testimonial Area End -->
</template>

<script>
export default {
  name: "TestimonialArea"
}
</script>

<style scoped>

</style>