<template>
  <!-- Slider Area Start -->
  <section class="ekifa-slider-area">
    <div class="ekifa-slider owl-carousel owl-theme">
      <carousel
          :autoplay="true"
          :autoplayTimeout="10000"
          :loop="true"
          :navigationEnabled="false"
          :paginationEnabled="false"
          :per-page="1"
          :speed="700"
          :center-mode="true"
      >
        <slide>
          <div class="item">
            <img :src="require('../assets/img/slider-1.jpeg')" alt="Slider 1" class="zoom">
            <div class="cover">
              <div class="container">
                <div class="header-content">
                  <div class="line animated bounceInLeft">
                  </div>
                  <h2>Get help</h2>
                  <h1>From Our Expert
                  </h1>
                  <a href="#">
                    <h4>Explore More <i class="fa fa-long-arrow-right"></i></h4>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </slide>
        <slide>
          <div class="item">
            <img :src="require('../assets/img/slider-2.jpeg')" alt="Slider 2" class="zoom">
            <div class="cover">
              <div class="container">
                <div class="header-content">
                  <div class="header-content">
                    <div class="line animated bounceInLeft">
                    </div>
                    <h2>Make a good plan
                    </h2>
                    <h1>Grow your business
                    </h1>
                    <a href="#">
                      <h4>Explore More
                        <i class="fa fa-long-arrow-right">
                        </i>
                      </h4>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </slide>
      </carousel>
    </div>
  </section>
  <!-- Slider Area Start -->
</template>

<script>
export default {
  name: "HomeSlider"
}
</script>

<style scoped>

</style>