<template>
 <div>
   <!-- Twitter Area Start -->
   <section class="ekifa-twitter-area">
     <b-container>
       <b-row>
         <b-col>
           <div class="twitter-post">
             <div class="twitter-post-icon">
               <i class="fa fa-twitter"></i>
             </div>
             <div class="single-twite">
               <p>"Check out 'The Associates - Law Firm' on <a href="#">#someLink</a> by <a href="#">@SomeLink</a> <a href="#">#someLink</a>"</p>
             </div>
           </div>
         </b-col>
       </b-row>
     </b-container>
   </section>
   <!-- Twitter Area End -->


   <!-- Footer Area Start -->
   <footer class="ekifa-footer-area">
     <div class="footer-top section_50">
       <b-container>
         <b-row>
           <b-col lg="3" md="6">
             <div class="single-footer-widget">
               <div class="footer-logo">
                 <b-link to="/test">
                   <img  style="display: block; margin-left: auto;margin-right: auto;width: 100%"
                         :src="require('../assets/img/logo-transparent-white.png')" alt="associates logo" />
                 </b-link>
               </div>
<!--               <p>Aliquip exa consequat dui aut repahend vouptate elit cilum fugiat pariatur lorem dolor cit amet consecter adipisic elit sea vena eiusmod nulla</p>-->
               <!----- Social Menu--------->
<!--               <br>-->
               <br>
               <social-menu style="text-align: center;" main-class="footer-social"/>
             </div>
           </b-col>
           <b-col lg="3" md="6">
             <div class="single-footer-widget">
               <h3>recent post</h3>
               <div class="latest-post-footer clearfix">
                 <div class="latest-post-footer-left">
                   <img :src="require('../assets/img/footer-post-2.jpg')" alt="post" />
                 </div>
                 <div class="latest-post-footer-right">
                   <h4><a href="javascript:;">How to choose your lawyer</a></h4>
                   <p>April 10 - 2023</p>
                 </div>
               </div>
               <div class="latest-post-footer clearfix">
                 <div class="latest-post-footer-left">
                   <img :src="require('../assets/img/footer-post-3.jpg')" alt="post" />
                 </div>
                 <div class="latest-post-footer-right">
                   <h4><a href="javascript:;">What it takes to be a great lawyer</a></h4>
                   <p>April 12 - 2023</p>
                 </div>
               </div>
             </div>
           </b-col>
           <b-col lg="3" md="6">
             <div class="single-footer-widget">
               <h3>main links</h3>
               <ul>
                 <li><a href="/about"><i class="fa fa-angle-double-right "></i> About The Associates</a></li>
                 <li><a href="/services"><i class="fa fa-angle-double-right "></i> Our Practices</a></li>
                 <li><a href="/team"><i class="fa fa-angle-double-right "></i> Our Team</a></li>
                 <li><a href="/blog"><i class="fa fa-angle-double-right "></i> Our Blog</a></li>
                 <li><a href="/cases"><i class="fa fa-angle-double-right "></i> Past Cases</a></li>
                 <li><a href="/resources/links"><i class="fa fa-angle-double-right "></i> Useful Resources</a></li>
                 <li><a href="/contact"><i class="fa fa-angle-double-right "></i> Contact Us</a></li>
               </ul>
             </div>
           </b-col>
           <b-col lg="3" md="6">
             <div class="single-footer-widget footer-contact">
               <h3>Contact Info</h3>
               <p><i class="fa fa-map-marker"></i> Center 114 - Main Road <br> Office 12 - 2nd floor <br> Baabda, Lebanon </p>
               <p><i class="fa fa-phone"></i> 012-3456-789</p>
               <p><i class="fa fa-envelope-o"></i> info@theassociates.me</p>
               <p><i class="fa fa-fax"></i> 112-3456-7898</p>
             </div>
           </b-col>
         </b-row>
       </b-container>
     </div>
     <div class="footer-copyright">
       <b-container>
         <b-row>
           <b-col lg="6">
             <div class="copyright-left">
               <p>Copyright &copy; 2023 | Creation by
                 <a href="Https://www.forswitch.com">
                   <img :src="require('../assets/img/forswitch-logo.png')" alt="ForSwitchs" style="width: 35%">
                 </a>
               </p>
             </div>
           </b-col>
           <b-col lg="6">
             <div class="copyright-right">
               <ul>
                 <li><b-link to="/test">home</b-link></li>
                 <li><b-link to="/about">about</b-link></li>
                 <li><b-link to="/services">services</b-link></li>
                 <li><b-link to="/team">team</b-link> </li>
                 <li><b-link to="/cases">cases</b-link> </li>
                 <li><b-link to="/blog">blog</b-link></li>
                 <li><b-link to="/resources/legal-guides">resources</b-link></li>
                 <li><b-link to="/contact">contact</b-link> </li>
               </ul>
             </div>
           </b-col>
         </b-row>
       </b-container>
     </div>
   </footer>
   <!-- Footer Area End -->
 </div>
</template>

<script>
import SocialMenu from "../components/menus/SocialMenu";
export default {
  name: "AppFooter",
  components: {SocialMenu}
}
</script>

<style scoped>

</style>