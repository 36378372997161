<template>
  <!-- Promo Area Start -->
  <section class="ekifa-service-area section_t_80 section_b_50">
    <b-container>
      <b-row>
        <b-col>
          <div class="site-heading">
            <h2>our services</h2>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3" v-for="(item, key) in promoData" :key="key">
          <router-link :to="item.path">
            <div class="single-promo">
              <div class="promo-icon">
                <i :class="`fa fa-${item.icon}`"></i>
              </div>
              <div class="promo-text">
                <h3>{{item.title}}</h3>
                <p>{{item.description}}</p>
              </div>
            </div>
          </router-link>
        </b-col>
      </b-row>
    </b-container>
  </section>
  <!-- Promo Area End -->
</template>

<script>
import servicesHomeData from '../json/services-home.json'
export default {
  name: "PromoArea",
  data() {
    return {
      promoData: servicesHomeData
    }
  }
}
</script>

<style scoped>

</style>