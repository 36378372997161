/*=========================================================================================
File Name: vueKitPlugins.js
Description: Insert plugins here.
----------------------------------------------------------------------------------------
Project Name: The Associates
Version: 1.0.0
Author: Rodrigue Fares
Copyright 2023
==========================================================================================*/


/**============== BootstrapVue ===================***/
import {BootstrapVue, IconsPlugin} from "bootstrap-vue";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.min.css'

/**============== Popper JS ===================***/
import 'popper.js/dist/popper.min'

/**============== Font Awesome ===================***/
import 'font-awesome/css/font-awesome.css'

/**============== Custom Style ===================***/
import '../assets/css/style.css';
import '../assets/css/responsive.css';

/**============== Vue Carousel ===================***/
import VueCarousel from 'vue-carousel';

/**============== AOS ===================***/
import 'aos/dist/aos.css'

/**============== Vue Click Outside ===================***/
import vClickOutside from 'v-click-outside'

/**============== Vue Apex Chart ===================***/
import VueApexCharts from 'vue-apexcharts'

export default {
    install(Vue) {
        Vue.use(BootstrapVue);
        Vue.use(IconsPlugin);
        Vue.use(VueCarousel);
        Vue.use(vClickOutside);
        Vue.use(VueApexCharts);
        Vue.component('apexchart', VueApexCharts)
    }
}


