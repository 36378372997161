<template>
  <b-container>
    <b-row>
      <b-col>
        <!-- Fullscreen search -->
        <div class="search-wrap" :style="`display:${isSearchActive}`">
          <div class="search-inner">
            <div class="search-cell">
              <form method="get" v-click-outside="onClickOutsideHandler">
                <div class="search-field-holder">
                  <input type="search" class="form-control main-search-input" placeholder="Type & hit enter...">
                  <i class="fa fa-search" id="search-close"></i>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- End fullscreen search -->
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "SearchOverlay",
  props: {
    isSearchActive: {
      type: String,
      default: function () {
        return 'none';
      }
    }
  },
  data() {
    return {
      overlay: false
    }
  },
  methods: {
    onClickOutsideHandler() {
      if(this.overlay) {
        this.$emit('searchHandler', 'stop')
        this.overlay = false;
        return;
      }

      this.overlay = true;
    }
  }
}
</script>

<style scoped>

</style>