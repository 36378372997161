<template>
  <!-- Service Area Start -->
  <section class="ekifa-service-area section_80">
    <b-container>
      <b-row>
        <b-col>
          <div class="site-heading">
            <h2>our services</h2>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" v-for="(item, key) in services" :key="key">
          <div class="single-service">
            <div class="service-img">
              <img :src="item.img" alt="service image" />
            </div>
            <div class="service-text">
              <h3><b-link :to="item.title.path">{{item.title.name}}</b-link></h3>
              <p>{{item.description}}</p>
              <div class="service-hover">
                <b-link :to="item.hover.path">
                  <i :class="`fa fa-${item.hover.icon}`"></i>
                </b-link>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="load-btn">
            <b-link class="ekifa-btn">load more services</b-link>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
  <!-- Service Area End -->
</template>

<script>
export default {
  name: "ServiceArea",
  data() {
    return {
      services: [
        {
          img: require('../assets/img/service-2.jpg'),
          title: {
            path: '',
            name: 'Business accounting'
          },
          description: 'Interdum iusto pulvinar consequuntur augue optio repellat fuga Purus expedita fusce temp oribus.',
          hover: {
            path: '',
            icon: 'arrow-right'
          }
        },
        {
          img: require('../assets/img/service-3.jpg'),
          title: {
            path: '',
            name: 'Expert Team Mates'
          },
          description: 'Interdum iusto pulvinar consequuntur augue optio repellat fuga Purus expedita fusce temp oribus.',
          hover: {
            path: '',
            icon: 'arrow-right'
          }
        },
        {
          img: require('../assets/img/service-4.jpg'),
          title: {
            path: '',
            name: 'Consulting Idea'
          },
          description: 'Interdum iusto pulvinar consequuntur augue optio repellat fuga Purus expedita fusce temp oribus.',
          hover: {
            path: '',
            icon: 'arrow-right'
          }
        },
        {
          img: require('../assets/img/service-1.jpeg'),
          title: {
            path: '',
            name: 'Startup Consultation'
          },
          description: 'Interdum iusto pulvinar consequuntur augue optio repellat fuga Purus expedita fusce temp oribus.',
          hover: {
            path: '',
            icon: 'arrow-right'
          }
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>