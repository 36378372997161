<template>
  <div>
    <!-- Home Slider Component -->
    <home-slider/>
<!--    &lt;!&ndash; Promo Area Component &ndash;&gt;-->
<!--    <promo-area/>-->
    <!-- Company Area Component -->
    <company-area/>
    <!-- Counter Area Component -->
    <counter-area/>
    <!-- Promo Area Component -->
    <promo-area/>
<!--    &lt;!&ndash; Service Area Component &ndash;&gt;-->
<!--    <service-area/>-->
    <!-- Try Area Component -->
<!--    <try-area/>-->
    <!-- Query Area Component -->
    <query-area/>
    <!-- Testimonial Area Component -->
    <testimonial-area/>
    <!-- Blog Area Component -->
    <home-blog-area/>
  </div>
</template>

<script>
import HomeSlider from "../components/HomeSlider";
import PromoArea from "../components/PromoArea";
import CompanyArea from "../components/CompanyArea";
import CounterArea from "../components/CounterArea";
import ServiceArea from "../components/ServiceArea";
import TryArea from "../components/TryArea";
import QueryArea from "../components/QueryArea";
import TestimonialArea from "../components/TestimonialArea";
import HomeBlogArea from "../components/HomeBlogArea";

export default {
  name: 'Home',
  components: {
    HomeBlogArea,
    TestimonialArea,
    QueryArea, TryArea, ServiceArea, CounterArea, CompanyArea, PromoArea, HomeSlider}
}
</script>
