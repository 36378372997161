
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vueKitPlugins from "./plugins/vueKitPlugins";
import VueMeta from 'vue-meta';
import AOS from "aos";


Vue.config.productionTip = false;
Vue.use(vueKitPlugins);
Vue.use(VueMeta, {refreshOnceOnNavigation: true});

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});

new Vue({
  created() {
    AOS.init({
      duration: 2000,
      delay: 300,

    })
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app')
