<template>
  <div id="chart">
    <apexchart type="polarArea" :options="chartOptions" :series="series" height="345"></apexchart>
  </div>
</template>

<script>
export default {
  name: "CompanyValueChart",
  data() {
    return {
      series: [14, 23, 21, 17, 15, 10, 12, 17],
      chartOptions: {
        chart: {
          type: 'polarArea',
        },
        labels: ['Leadership', 'Collaboration', 'Responsibility', 'Global Network', 'Teamwork', 'Integrity', 'Empathy' , 'Quality'],
        stroke: {
          colors: ['#fff']
        },
        fill: {
          opacity: 0.8
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      }
    }
  }
}
</script>

<style scoped>

</style>