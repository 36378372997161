<template>
  <b-container>
    <b-navbar toggleable="lg" class="m-0 p-0">
      <b-navbar-brand href="#">
        <div class="site-logo">
          <router-link to="/test">
            <img :src="require('../../assets/img/logo.png')" alt="site logo" />
          </router-link>
        </div>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <template v-for="(item, key) in menus">
            <b-nav-item v-if="!item.submenus" :key="key" :active="!!item.isActive" :to="{name: item.name, path: item.path}">{{item.title}}</b-nav-item>
<!--            <b-nav-item v-else-if="!item.submenus" :key="key" :active="!!item.isActive" :to="{name: item.name, path: item.path}">{{item.title}}</b-nav-item>-->
            <b-nav-item-dropdown :text="item.title" :active="!!item.isActive" :key="key" right v-else>
              <template v-for="(subItem, i) in item.submenus">
                <b-dropdown-item @click="subMenuHandler(subItem)">{{subItem.title}}</b-dropdown-item>
              </template>
            </b-nav-item-dropdown>
          </template>
        </b-navbar-nav>
<!--        <b-navbar-nav class="ml-5">-->
<!--          <b-nav-item>-->
<!--            <div class="search-box"><i class="fa fa-search" @click="searchHandler()"></i></div>-->
<!--          </b-nav-item>-->
<!--        </b-navbar-nav>-->
      </b-collapse>
    </b-navbar>
    <search-overlay :is-search-active="isSearchActive" @searchHandler="searchHandler"/>
  </b-container>
</template>

<script>
import SearchOverlay from "../SearchOverlay";
import { mapActions } from "vuex";

const projects = require('../../json/projects.json')
export default {
  name: "MainMenu",
  components: {SearchOverlay},
  data() {
    return {
      menus: [
        {
          title: 'Home',
          name: 'Home',
          path: '/'
        },
        {
          title: 'About',
          name: 'About',
          path: '/about'
        },
        {
          title: 'services',
          submenus: [
            {
              title: 'All Services',
              name: 'AllServices',
              path: '/services'
            },
            {
              title: 'Corporate and Commercial Law',
              name: 'PracticeServices',
              path: '/services/corporate-commercial-law',
              id: 'CorporateAndCommercialLaw'
            },
            {
              title: 'Intellectual Property Law',
              name: 'PracticeServices',
              path: '/services/intellectual-property-law',
              id: 'IntellectualPropertyLaw'
            },
            {
              title: 'Banking and Finance',
              name: 'PracticeServices',
              path: '/services/banking-finance',
              id: 'BankingAndFinance'
            },
            {
              title: 'Construction and Engineering',
              name: 'PracticeServices',
              path: '/services/construction-engineering',
              id: 'ConstructionAndEngineering'
            },
            {
              title: 'Property and Real Estate',
              name: 'PracticeServices',
              path: '/services/property-real-estate',
              id: 'PropertyAndRealEstate'
            },
            {
              title: 'Maritime Trade and Insurance',
              name: 'PracticeServices',
              path: '/services/maritime-trade-insurance',
              id: 'MaritimeTradeAndInsurance'
            },
            {
              title: 'Disputes, Litigation and Arbitration',
              name: 'PracticeServices',
              path: '/services/disputes-litigation-arbitration',
              id: 'DisputesLitigationAndArbitration'
            },
            {
              title: 'Criminal Law',
              name: 'PracticeServices',
              path: '/services/criminal-law',
              id: 'CriminalLaw'
            },
            {
              title: 'Labor Law',
              name: 'PracticeServices',
              path: '/services/labor-law',
              id: 'LaborLaw'
            },
            {
              title: 'Tax',
              name: 'PracticeServices',
              path: '/services/tax',
              id: 'Tax'
            },
            {
              title: 'Family Law',
              name: 'PracticeServices',
              path: '/FamilyLaw',
              id: 'FamilyLaw'
            },
            {
              title: 'Power and Energy',
              name: 'PracticeServices',
              path: '/services/power-energy',
              id: 'PowerAndEnergy'
            }
          ]
        },
        {
          title: 'Our Team',
          name:  'Team',
          path:  '/team'
        },
        {
          title: 'Cases',
          name: 'Cases',
          path: '/cases'
        },
        {
          title: 'Blog',
          name: 'Blog',
          path: '/blog'
        },
        {
          title: 'Resources',
          submenus: [
            {
              title: 'Legal Guides',
              name: 'LegalGuides',
              path: '/resources/legal-guides'
            },
            {
              title: 'FAQ',
              name: 'FAQ',
              path: '/resources/faq'
            },
            {
              title: 'Useful Links',
              name: 'ExternalLinks',
              path: '/resources/links'
            }
          ]
        },
        {
          title: 'Contact',
          name: 'Contact',
          path: '/contact'
        }
      ],
      isSearchActive: 'none'
      // projects: projects.filter((element) => element.type === "all")
    }
  },
  mounted() {
     this.menuHandler();
  },
  methods: {
    ...mapActions(["updatePractice"]),
    menuHandler() {
      const routeName = this.$route.name;

      this.menus = this.menus.map((item) => {
        if(item.name === routeName) {
          item.isActive = true;
        }

        return {
          ...item
        }
      })
    },
    subMenuHandler(subItem){
      this.updatePractice(projects.find((element) => element.name === subItem.title && element.type === "all"));
      if(subItem.id){
        this.$router.push({
          name: subItem.name,
          path: subItem.path,
          params: {
            idPractice: subItem.id,
            item: projects.find((element) => element.name === subItem.title && element.type === "all"),
            practices: projects.filter((element) => element.type === subItem.id)
          }
        })
      }
      else {
        this.$router.push({
          name: subItem.name,
          path: subItem.path
        })
      }
    },
    searchHandler(value) {
      if(value === 'stop') {
        this.isSearchActive = 'none';
        return;
      }

      this.isSearchActive = 'block';
    }
  }
}
</script>

<style scoped>

</style>