<template>
  <!-- Query Area Start -->
  <section class="ekifa-query-area">
    <b-container>
      <b-row>
        <b-col md="6">
          <div class="query-choose-left">
            <h3>Request For Call Back!</h3>
            <p>Please fill out the form and press the submit button. <br>We will get back to you with 1-2 business days.</p>
          </div>
        </b-col>
        <b-col lg="4" md="6" offset-md="0" offset-lg="2">
          <div class="get-quote-form">
            <form>
              <p>
                <b-form-input v-model="quote.name" type="text" placeholder="Full Name"/>
              </p>
              <p>
                <b-form-input v-model="quote.email" type="email" placeholder="Email Address"/>
              </p>
              <p>
                <b-form-select v-model="quote.type" :options="options"></b-form-select>
              </p>
              <p>
                <b-form-textarea
                    id="textarea-large"
                    size="lg"
                    placeholder="Write your message here"
                    v-model="quote.description"
                ></b-form-textarea>
              </p>
              <p>
                <b-button type="submit">Send request</b-button>
              </p>
            </form>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
  <!-- Query Area End -->
</template>

<script>
export default {
  name: "QueryArea",
  data() {
    return {
      selected: null,
      options: [
        { value: null, text: 'I would like to discuss:' },
        { value: 'a', text: 'About Investment' },
        { value: 'b', text: 'Facilities' },
        { value: 'c', text: 'Tax planning' },
        { value: 'd', text: 'Extra Features' },
      ],
      quote: {
        name: '',
        email: '',
        type: null,
        description: ''
      }
    }
  }
}
</script>

<style scoped>

</style>