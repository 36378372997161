<template>
  <!-- Blog Area Start -->
  <section class="ekifa-bolg-area section_t_80 section_b_50">
    <b-container>
      <b-row>
        <b-col>
          <div class="site-heading">
            <h2>Recent News</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis accumsan nisi Ut ut felis congue nisl hendrerit commodo. </p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" lg="4" v-for="(item, key) in items" :key="key">
          <div class="single-blog home-blog">
            <div class="blog-img">
              <b-link :to="item.path">
                <img :src="require(`../assets/img/${item.img}`)" alt="blog" />
              </b-link>
            </div>
            <div class="blog-text">
              <div class="blog-meta">
                <p><i class="fa fa-user"></i> {{item.userName}}</p>
                <p><i class="fa fa-clock-o"></i> {{item.date}}</p>
              </div>
              <h3><b-link :to="item.path">{{item.title}}</b-link></h3>
              <p>{{item.description}}</p>
            </div>
            <div class="blog-btn">
              <b-link :to="item.path"><i class="fa fa-arrow-right"></i></b-link>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
  <!-- Blog Area End -->
</template>

<script>
export default {
  name: "HomeBlogArea",
  data() {
    return {
      items: [
        {
          path: '/blog/single-blog',
          img: 'blog-1.jpeg',
          userName: 'By Jamil Fares',
          date: '24 Apr, 2023',
          title: 'How to choose your lawyer',
          description: 'Aliquip exa consequat dui aut repahend vouptate elit cilum fugiat pariatur lorem dolor cit amet consecter adipisic elit sea vena eiusmod nulla'
        },
        {
          path: '/blog/single-blog',
          img: 'blog-2.jpeg',
          userName: 'By Christelle Helou',
          date: '24 Jun, 2023',
          title: 'Settling your Inheritance',
          description: 'Aliquip exa consequat dui aut repahend vouptate elit cilum fugiat pariatur lorem dolor cit amet consecter adipisic elit sea vena eiusmod nulla'
        },
        {
          path: '/blog/single-blog',
          img: 'blog-3.jpeg',
          userName: 'By Jamil Fares',
          date: '24 Jun, 2023',
          title: 'Land Ownership',
          description: 'Aliquip exa consequat dui aut repahend vouptate elit cilum fugiat pariatur lorem dolor cit amet consecter adipisic elit sea vena eiusmod nulla'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>