<template>
  <!-- Try Area Start -->
  <section class="ekifa-try-area section_80">
    <b-container>
      <b-row>
        <b-col md="9">
          <div class="try-box-left">
            <h3>Are You Ready for the Talent Consultant ?</h3>
          </div>
        </b-col>
        <b-col md="3">
          <div class="try-box-right">
            <b-link class="ekifa-btn">try it now</b-link>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
  <!-- Try Area End -->
</template>

<script>
export default {
  name: "TryArea"
}
</script>

<style scoped>

</style>