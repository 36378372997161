<template>
  <div id="app">
    <!--    Preloader-->
    <preloader :is-loading="isLoading"/>
    <!--    Header Section-->
    <router-view name="header" />
    <!--    Body Section-->
    <router-view />
    <!--    Footer Section-->
    <router-view name="footer" />
    <b-sidebar
        id="sidebar-backdrop"
        backdrop-variant="dark"
        backdrop
        shadow
    >
      <div class="px-3 py-2">
        <contact-form-area/>

        <!--        <b-form-group label="Backdrop variant" label-for="backdrop-variant">-->
        <!--          <b-form-select id="backdrop-variant" v-model="variant" :options="variants"></b-form-select>-->
        <!--          <contact-form-area/>-->
        <!--        </b-form-group>-->
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import Preloader from "./components/Preloader";
import ContactFormArea from "@/components/ContactFormArea";
export default {
  name: 'App',
  components: {Preloader, ContactFormArea},
  metaInfo: {
    title: 'The Associates - Law Firm',
    titleTemplate: 'The Associates - Law Firm || %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  data() {
    return {
      isLoading: false,
    }
  },
  mounted() {
    this.preloaderHandler();
  },
  methods: {
    preloaderHandler() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false
      }, 2000)
    }
  }
}
</script>

<style scoped>

</style>
