<template>
  <!-- Preloader Start -->
  <div>
    <loading :active="isLoading" :is-full-page="true">
      <div id="preloader">
        <div class="spinner">
          <div class="spin1"></div>
          <div class="spin2"></div>
          <div class="spin3"></div>
          <div class="spin4"></div>
          <div class="spin5"></div>
        </div>
      </div>
    </loading>
  </div>
  <!-- Preloader End -->
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: "Preloader",
  components: {Loading},
  props: {
    isLoading: {
      type: Boolean,
      default: function () {
        return true;
      }
    }
  }
}
</script>

<style scoped>

</style>